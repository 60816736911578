<div class="page-header">
  <h1>{{ "passwordGenerator" | i18n }}</h1>
</div>
<app-callout type="info" *ngIf="enforcedPolicyOptions?.inEffect()">
  {{ "passwordGeneratorPolicyInEffect" | i18n }}
</app-callout>
<div class="card card-password bg-light my-4">
  <div class="card-body">
    <div class="password-wrapper" [innerHTML]="password | colorPassword" appSelectCopy></div>
  </div>
</div>
<div class="form-group">
  <div class="form-check form-check-inline" *ngFor="let o of passTypeOptions">
    <input
      class="form-check-input"
      type="radio"
      [(ngModel)]="options.type"
      name="Type_{{ o.value }}"
      id="type_{{ o.value }}"
      [value]="o.value"
      (change)="saveOptions()"
      [checked]="options.type === o.value"
    />
    <label class="form-check-label" for="type_{{ o.value }}">
      {{ o.name }}
    </label>
  </div>
</div>
<ng-container *ngIf="options.type === 'passphrase'">
  <div class="row">
    <div class="form-group col-4">
      <label for="num-words">{{ "numWords" | i18n }}</label>
      <input
        id="num-words"
        class="form-control"
        type="number"
        min="3"
        max="20"
        [(ngModel)]="options.numWords"
        (blur)="saveOptions()"
      />
    </div>
    <div class="form-group col-4">
      <label for="word-separator">{{ "wordSeparator" | i18n }}</label>
      <input
        id="word-separator"
        class="form-control"
        type="text"
        maxlength="1"
        [(ngModel)]="options.wordSeparator"
        (blur)="saveOptions()"
      />
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        id="capitalize"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.capitalize"
        [disabled]="enforcedPolicyOptions?.capitalize"
      />
      <label for="capitalize" class="form-check-label">{{ "capitalize" | i18n }}</label>
    </div>
    <div class="form-check">
      <input
        id="include-number"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.includeNumber"
        [disabled]="enforcedPolicyOptions?.includeNumber"
      />
      <label for="include-number" class="form-check-label">{{ "includeNumber" | i18n }}</label>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="options.type === 'password'">
  <div class="row">
    <div class="form-group col-4">
      <label for="length">{{ "length" | i18n }}</label>
      <input
        id="length"
        class="form-control"
        type="number"
        min="5"
        max="128"
        [(ngModel)]="options.length"
        (blur)="saveOptions()"
        (change)="lengthChanged()"
      />
    </div>
    <div class="form-group col-4">
      <label for="min-number">{{ "minNumbers" | i18n }}</label>
      <input
        id="min-number"
        class="form-control"
        type="number"
        min="0"
        max="9"
        (blur)="saveOptions()"
        [(ngModel)]="options.minNumber"
        (change)="minNumberChanged()"
      />
    </div>
    <div class="form-group col-4">
      <label for="min-special">{{ "minSpecial" | i18n }}</label>
      <input
        id="min-special"
        class="form-control"
        type="number"
        min="0"
        max="9"
        (blur)="saveOptions()"
        [(ngModel)]="options.minSpecial"
        (change)="minSpecialChanged()"
      />
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        id="uppercase"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.uppercase"
        [disabled]="enforcedPolicyOptions?.useUppercase"
      />
      <label for="uppercase" class="form-check-label">A-Z</label>
    </div>
    <div class="form-check">
      <input
        id="lowercase"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.lowercase"
        [disabled]="enforcedPolicyOptions?.useLowercase"
      />
      <label for="lowercase" class="form-check-label">a-z</label>
    </div>
    <div class="form-check">
      <input
        id="numbers"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.number"
        [disabled]="enforcedPolicyOptions?.useNumbers"
      />
      <label for="numbers" class="form-check-label">0-9</label>
    </div>
    <div class="form-check">
      <input
        id="special"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="options.special"
        [disabled]="enforcedPolicyOptions?.useSpecial"
      />
      <label for="special" class="form-check-label">!@#$%^&amp;*</label>
    </div>
    <div class="form-check">
      <input
        id="ambiguous"
        class="form-check-input"
        type="checkbox"
        (change)="saveOptions()"
        [(ngModel)]="avoidAmbiguous"
      />
      <label for="ambiguous" class="form-check-label">{{ "ambiguous" | i18n }}</label>
    </div>
  </div>
</ng-container>
<div class="d-flex">
  <div>
    <button type="button" class="btn btn-primary" (click)="regenerate()">
      {{ "regeneratePassword" | i18n }}
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="copy()">
      {{ "copyPassword" | i18n }}
    </button>
  </div>
  <div class="ml-auto">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="history()"
      appA11yTitle="{{ 'passwordHistory' | i18n }}"
    >
      <i class="bwi bwi-clock bwi-lg" aria-hidden="true"></i>
    </button>
  </div>
</div>
<ng-template #historyTemplate></ng-template>
